import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';

// Use calendar defaults (optional)

import VCalendar, { Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';

// Use plugin with optional defaults
// App.use(VCalendar, {})

/* add icons to the library */
library.add(faUserSecret);

createApp(App)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('v-calendar', VCalendar)
  .component('VCalendar', Calendar)
  .component('VDatePicker', DatePicker)
  .mount('#app');
