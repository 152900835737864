<script >

export default {
  name: 'AppointmentNavBar',
  props: {
    store: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="sticky bg-neutral-800 top-0">
    <div class="container mx-auto">
      <div class="flex items-center mx-4">
      <!-- Logo -->
      <div class="mr-3">
        <router-link to="/">
        <img src="../../../../../assets/logo.png" alt="" class="w-16"/>
        </router-link>
      </div>

        <div class="flex flex-col text-white text-sm items-start">
            <div class="font-bold text-md">{{ store.name }}</div>
            <div class="text-[12px]">{{ store.phone }}</div>
            <div class="text-[12px]">{{ store.address }}</div>
        </div>


    </div>

    </div>

  </div>
</template>

<style scoped>

</style>
