
import { Options, Vue } from 'vue-class-component';
import HomeBanner from '@/components/home-component/HomeBanner.vue';
import RocketTicketsWidget from '@/components/home-component/RocketTicketsWidget.vue';
import NavBar from '@/template/NavBar.vue';
import Footer from '@/template/Footer.vue';

@Options({
  components: {
    NavBar,
    Footer,
    HomeBanner,
    RocketTicketsWidget,
  },
})
export default class HomeView extends Vue { }
