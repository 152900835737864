
import Vue from 'vue';
import Options from 'vue-class-component';

// @Options({})
// export default RocketTicketsWidget

export default {
  name: 'RocketTicketsWidget',
};
