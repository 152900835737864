<template>
  <div class="home">
    <AppointmentNavBar :store="store"/>
    <LoadingScreen v-if="isLoadingStoreData"/>
    <ApptBookStatusBar
        v-if="!isLoadingStoreData"
        @switchTab="switchTab"
        v-bind:currentTab="currentTab"
        @bookAppointment="bookAppointment"
        @startOver="startOver"
        :isBooking="isBooking"
        :isBooked="isBooked"
    />
    <div class="container mx-auto px-4" v-if="!isLoadingStoreData">
      <ServicesTab
          v-if="currentTab === 'Services'"
          :services="service_groups"
          :selectingServices="selectingServices"
          @addService="addService"
          @removeService="removeService"
      />
      <StaffsTab
          v-if="currentTab === 'Technicians'"
          :staffs="staffs"
          :selectingStaffs="selectingStaffs"
          :isAnyAvailableTech="isAnyAvailableTech"
          :selectingServices="selectingServices"
          @addStaff="addStaff"
          @removeStaff="removeStaff"
          @switchAnyAvailableTech="switchAnyAvailableTech"
      />
      <DateTimeTab
          v-if="currentTab === 'Date Time'"
          :selectingDate="selectingDate"
          :selectingTime="selectingTime"
          :selectingStaffs="selectingStaffs"
          :selectingServices="selectingServices"
          :selectingStartDateTime="selectingStartTime"
          :isAnyAvailableTime="isAnyAvailableTime"
          :store="store"
          :time_offs="time_offs"
          :appointments="appointments"
          :fetchingAppointments="fetchingAppointments"
          @selectDate="selectDate"
          @selectTime="selectTime"
          @switchAnyAvailableTime="switchAnyAvailableTime"
      />
      <CustomerInfoTab
          v-if="currentTab === 'Your Info'"
          :editingCustomer="editingCustomer"
          @updateCustomer="updateCustomer"
      />
      <AppointmentSummaryTab
          v-if="currentTab === 'Review'"
          :isBooked="isBooked"
          :selectingServices="selectingServices"
          :selectingStaffs="selectingStaffs"
          :selectingDate="selectingDate"
          :selectingTime="selectingTime"
          :editingCustomer="editingCustomer"
          :editingNote="editingNote"
          :isAnyAvailableTech="isAnyAvailableTech"
          :preferredReminderMethod="preferredReminderMethod"
          :isConsented="isConsented"
          :wantsToReceiveConfirmationRightAfter="wantsToReceiveConfirmationRightAfter"
          :is24HoursReminded="is24HoursReminded"
          :store="store"
          @updateNote="updateNote"
          @updateReminderOptions="updateReminderOptions"
      />

    </div>
    <div class="h-16"></div>
  </div>
</template>

<script>
// import NavBar from '@/template/NavBar.vue';
import ApptBookStatusBar from '@/views/projects/rocket-tickets/appointment/book-appointment/StatusBar.vue';
import ServicesTab
  from '@/views/projects/rocket-tickets/appointment/book-appointment/services-tab/ServicesTab.vue';
import StaffsTab
  from '@/views/projects/rocket-tickets/appointment/book-appointment/staffs-tab/StaffsTab.vue';
import DateTimeTab
  from '@/views/projects/rocket-tickets/appointment/book-appointment/datetime-tab/DateTimeTab.vue';
import CustomerInfoTab
  from '@/views/projects/rocket-tickets/appointment/book-appointment/customer-tabs/CustomerInfoTab.vue';
import AppointmentSummaryTab
  from '@/views/projects/rocket-tickets/appointment/book-appointment/summary-tab/AppointmentSummaryTab.vue';
import AppointmentNavBar
  from '@/views/projects/rocket-tickets/appointment/book-appointment/AppointmentNavBar.vue';
import LoadingScreen from '@/views/projects/rocket-tickets/appointment/book-appointment/LoadingScreen.vue';

export default {
  name: 'AboutView',
  components: {
    LoadingScreen,
    AppointmentNavBar,
    AppointmentSummaryTab,
    CustomerInfoTab: CustomerInfoTab,
    DateTimeTab,
    StaffsTab,
    ServicesTab,
    ApptBookStatusBar,
    // Footer,
    // RocketTicketsWidget,
  },
  props: {
    storeCuteID: {
      type: String,
    },
  },
  data() {
    return {
      backendURL: 'https://ground-control-79qq.onrender.com',
      // backendURL: 'http://192.168.0.117:5002',

      isLoadingStoreData: true,

      currentTab: 'Services',

      isBooking: false,
      isBooked: false,
      isAnyAvailableTech: false,
      isAnyAvailableTime: false,

      selectingStaffs: [],
      selectingServices: [],

      preferredReminderMethod: 'SMS',
      isConsented: true,
      // isConfirmed: false,
      wantsToReceiveConfirmationRightAfter: true,
      is24HoursReminded: true, // set to true if the customer doesn't want to be reminded 24 hours before the appointment

      selectingStartTime: new Date(),
      selectingDate: this.initFormattedDate(),
      selectingTime: '',

      editingCustomer: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
      },
      editingNote: '',

      store: {},
      service_groups: [],
      staffs: [],
      time_offs: [],
      appointments: [],
      fetchingAppointments: false,
    };
  },
  async mounted() {
    // await new Promise(r => setTimeout(r, 2000));

    const url = `${this.backendURL}/get-store-data?store_cute_id=${this.storeCuteID}`;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let res = await fetch(url, requestOptions)

    if (res.status !== 200) {
      alert('Error: ' + res.status);
    }
    else {
      console.log('Success');
      res.json().then(data => {
        console.log(data);
        this.store = data.store;

        this.service_groups = data.service_groups;
        this.staffs = data.staffs;
        this.isLoadingStoreData = false;
        this.selectingDate = this.initFormattedDate();
        this.time_offs = data.time_offs;
        // this.appointments = data.appointments;
        // console.log(this.appointments);
      });
    }

  },
  methods: {
    initFormattedDate() {
      const timestamp = Date.now();
      const dateObject = new Date(timestamp);

      console.log(dateObject);

      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1)
          .padStart(2, '0'); // Month is zero-based
      const day = String(dateObject.getDate())
          .padStart(2, '0');

      return `${year}-${month}-${day}`;
    },

    formatPhoneNumber(phoneNumber) {
      const cleaned = phoneNumber.replace(/\D/g, '');

      // Check if the cleaned phone number has exactly 10 digits
      // const isValidNumber = /^[0-9]{10}$/.test(cleaned);
      //
      // if (!isValidNumber) {
      //   console.error('Invalid phone number. Please provide a 10-digit number.');
      //   return phoneNumber;
      // }

      // Format the phone number as (xxx) xxx-xxxx
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
    },

    switchTab(newTab) {
      this.currentTab = newTab;
    },

    addService(service) {
      console.log(`Main: Add ${service.short_name} service`);
      this.selectingServices.push(service);
    },

    removeService(service) {
      console.log(`Main: Remove ${service.short_name} service`);
      this.selectingServices.splice(this.selectingServices.indexOf(service), 1);
    },

    addStaff(staff) {
      console.log(`Main: Add ${staff.first_name} staff`);
      this.selectingStaffs.pop();
      this.isAnyAvailableTech = false;
      this.selectingStaffs.push(staff);
    },

    removeStaff(staff) {
      console.log(`Main: Remove ${staff.first_name} staff`);
      this.selectingStaffs.splice(this.selectingStaffs.indexOf(staff), 1);
    },

    switchAnyAvailableTech(isAnyAvailableTech) {
      this.isAnyAvailableTech = !isAnyAvailableTech;
      if (this.isAnyAvailableTech) {
        this.selectingStaffs = [];
      }
      console.log(`Main: Switch any available tech to ${isAnyAvailableTech}`);
    },

    async selectDate(date) {
      console.log(`Main: Select ${date} date`);

      console.log(typeof date);
      if (typeof date !== 'string') {
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1)
            .padStart(2, '0'); // Month is zero-based
        const day = String(date.getUTCDate())
            .padStart(2, '0');

        console.log(`Main a: Select ${year}-${month}-${day} date`);

        date = `${year}-${month}-${day}`;
      }

      console.log(`Main b: Select ${date} date`);

      this.isAnyAvailableTime = false;
      this.selectingDate = date;
      this.selectingTime = '';

      const url = `${this.backendURL}/get-appointments?store_id=${this.store.id}&date=${date}`;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      this.fetchingAppointments = true;
      let res = await fetch(url, requestOptions)

      if (res.status !== 200) {
        alert('Error: ' + res.status);
      } else {
        // console.log('Success');
        res.json()
            .then(data => {
              console.log(data);
              this.appointments = data.appointments;
              // console.log(this.appointments);
            });
      }

      this.fetchingAppointments = false;

    },

    switchAnyAvailableTime(isAnyAvailableTime) {
      this.isAnyAvailableTime = !isAnyAvailableTime;
      if (this.isAnyAvailableTime) {
        this.selectingTime = '';
      }
      console.log(`Main: Switch any available time to ${isAnyAvailableTime}`);
    },

    selectTime(time) {
      this.isAnyAvailableTime = false;

      if (time === this.selectingTime) {
        this.selectingTime = '';
        return;
      }

      this.selectingTime = time;
    },

    updateCustomer(customer) {
      this.editingCustomer = customer;
    },

    updateNote(note) {
      this.editingNote = note;
      console.log(this.editingNote);
    },

    validateAppointment() {
      if (this.selectingServices.length === 0) {
        alert('Please select at least one service');
        return false;
      }
      if (this.selectingStaffs.length === 0 && !this.isAnyAvailableTech) {
        alert('Please select at least one staff');
        return false;
      }
      if (this.selectingDate === '') {
        alert('Please select a date');
        return false;
      }
      if (this.selectingTime === '') {
        alert('Please select a time');
        return false;
      }
      if (this.editingCustomer.firstName === '') {
        alert('Please enter your first name');
        return false;
      }
      if (this.editingCustomer.lastName === '') {
        alert('Please enter your last name');
        return false;
      }
      if (this.editingCustomer.phone === '') {
        alert('Please enter your phone number');
        return false;
      }
      return true;
    },

    convertDateAndTimeToDateObject(date, time) {
      const year = date.slice(0, 4);
      const monthIndex = date.slice(5, 7) - 1;
      const day = date.slice(8, 10);

      let [hour, minute] = time.slice(0, -3)
          .split(':')
          .map(Number);

      const amPM = time.slice(-2);

      if (amPM === 'PM' && hour !== 12) {
        hour += 12;
      }

      return new Date(year, monthIndex, day, hour, minute);
    },

    async bookAppointment() {
      if (!this.validateAppointment()) {
        return;
      }
      console.log('Book appointment');

      const dateTimeNow = new Date();

      this.selectingStartTime = this.convertDateAndTimeToDateObject(this.selectingDate, this.selectingTime);

      const duration = this.selectingServices.reduce((a, b) => a + b.duration, 0);

      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      console.log(timeZone);

      const newCustomer = {
        id: 'temp-customer-id',
        account_id: this.store.id,
        account_name: this.store.name,
        account_email: this.store.email,
        first_name: this.editingCustomer.firstName,
        last_name: this.editingCustomer.lastName,
        alias: '',
        phone: this.editingCustomer.phone,
        email: this.editingCustomer.email,
        note: '',
        visit_count: 0,
        created_at: dateTimeNow,
        created_by: 'Website',
        is_verified: false,
        is_active: true,
      };

      console.log(newCustomer);

      // this.is24HoursReminded = !this.is24HoursReminded;

      const newAppointment = {
        id: 'temp-appointment-id',
        account_id: this.store.id,
        account_name: this.store.name,
        account_email: this.store.email,
        store: this.store,
        customer: newCustomer,
        employees: this.selectingStaffs,
        service_groups: [],
        services: this.selectingServices,
        start_time: this.selectingStartTime,
        time_zone: timeZone,
        duration: duration,
        created_at: dateTimeNow,
        created_by: 'Website',
        modified_at: null,
        modified_by: null,
        deleted_at: null,
        deleted_by: null,
        canceled_at: null,
        canceled_by: null,
        note: this.editingNote,
        is_canceled: false,
        is_time_off: false,
        is_confirmed: false,
        is_consented: this.isConsented,
        is_24_hours_reminded: (!this.is24HoursReminded), // reverse the value for the appointment reminder server to pick up
        is_2_hours_reminded: (!this.is24HoursReminded), // reverse the value for the appointment reminder server to pick up
        wants_to_receive_confirmation_right_after: this.wantsToReceiveConfirmationRightAfter,
        // is_reminded: false,
        appt_type: 'Online',
      };

      console.log(newAppointment);

      this.isBooking = true;

      const url = `${this.backendURL}/add-appointment`;

      let res = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': 'Basic super-secure'
        },
        body: JSON.stringify(newAppointment)
      });

      if (res.status === 200) {
        this.isBooking = false;
        this.isBooked = true;
      }
      else if (res.status === 403) {
        this.isBooking = false;
        console.log(res);
        res.json().then(data => {
          console.log(data['error']);
          alert(data['error']);
        });
      }
      else {
        this.isBooking = false;
        alert('Something went wrong. Please try again later or call us to book an appointment');
      }
    },

    startOver() {
      console.log('Start over');
      this.currentTab = 'Services';
      this.isBooking = false;
      this.isBooked = false;
      this.selectingStaffs = [];
      this.selectingServices = [];
      this.selectingDate = this.initFormattedDate();
      this.selectingTime = '';
      this.editingCustomer = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
      };
      this.editingNote = '';
    },

    updateReminderOptions(isConsented, wantsToReceiveConfirmationRightAfter, is24HoursReminded) {
      this.isConsented = isConsented;
      this.wantsToReceiveConfirmationRightAfter = wantsToReceiveConfirmationRightAfter;
      this.is24HoursReminded = is24HoursReminded;
    },
  },
};
</script>
