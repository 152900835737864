<template>
  <button class="md:w-32 text-indigo-500 font-bold border-b-indigo-500 border-t-white border-x-white border-0 rounded-xl px-2  md:py-2 text-[12px] md:text-md" type="button" v-if="currentTab === name" @click="switchCurrentTab(name)">
    {{ index }}<br/>{{ name }}
  </button>
  <button class="md:text-md text-gray-600 rounded-xl px-2 md:w-32 md:py-2 text-[12px]  hover:font-bold" type="button" v-else @click="switchCurrentTab(name)">
    {{ index }}<br/>{{ name }}
  </button>
</template>

<!--<script setup>-->
<!--const emits = defineEmits(["updatedcount"]);-->
<!--</script>-->

<script>
import Vue from 'vue';

export default {
  name: 'TabButton',
  components: {
  },
  props: {
    name: String,
    index: String,
    currentTab: String,
  },
  methods: {
    switchCurrentTab(newTab) {
      console.log(`Switch to ${newTab} tab`);
      this.$emit('switchTab', newTab);
    },
  },
};

</script>
